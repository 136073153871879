<template>
  <v-card class="pa-4" :class="darkmode || 'grey lighten-5'" flat>
    <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
      <v-btn @click="$router.go(-1)" depressed>
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("app.back") }}
      </v-btn>
    </div>
    <HeaderInfo :info="dataTable" showTotal chance />
    <div :class="darkmode || 'white'" class="pa-3">
      <div class="d-flex align-center">
        <v-text-field
          v-model="table.search"
          :label="$t('app.search')"
          outlined
          dense
          hide-details
          clearable
          style="max-width: 250px"
          prepend-inner-icon="mdi-magnify"
          class="d-inline-block mr-2 my-5"
        />
        <div class="d-inline-block font-weight-bold">
          {{ dataTable.body.data.length }}
          {{ $t("app.student") }}
        </div>
      </div>
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th rowspan="2">
                {{ $t("e_test.table_header.chance.name") }}
              </th>
              <th colspan="2" class="text-center">
                {{ $t("e_test.table_header.chance.chance") }}
              </th>
              <th colspan="1"></th>
              <th colspan="2" class="text-center">
                {{ $t("e_test.table_header.detail_subjects.duration") }}
              </th>
            </tr>
            <tr>
              <th class="text-center">Total</th>
              <!-- <th>
                {{ $t("e_test.table_header.chance.opportunity_missed") }}
              </th> -->
              <th class="text-center">
                {{ $t("e_test.table_header.chance.remaining_opportunity") }}
              </th>
              <th class="text-center"></th>
              <th class="text-center">Total</th>
              <!-- <th>
                {{ $t("e_test.table_header.chance.opportunity_missed") }}
              </th> -->
              <th class="text-center">
                {{ $t("e_test.table_header.chance.remaining_opportunity") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in dataTable.body.data">
              <tr v-if="item.is_generate" :key="index">
                <td>{{ item.name }}</td>
                <td class="text-center">
                  <v-edit-dialog
                    :return-value.sync="item.chance"
                    large
                    persistent
                    @save="saveChance(item)"
                  >
                    <v-btn :disabled="table.loading" color="primary">
                      {{ item.chance }}
                    </v-btn>
                    <template #input>
                      <v-text-field
                        v-model="item.chance"
                        :rules="[v => !!v || $t('app.required')]"
                        type="number"
                        single-line
                      />
                    </template>
                  </v-edit-dialog>
                </td>
                <!-- <td>{{ item.chance_used }}</td> -->
                <td class="text-center">{{ item.chance_remaining }}</td>
                <td></td>
                <td class="text-center">{{ item.duration }}</td>
                <!-- <td>{{ item.duration_used }}</td> -->
                <td class="text-center">
                  <v-edit-dialog
                    :return-value.sync="item.duration_remaining"
                    large
                    persistent
                    @save="
                      updateDuration(item.student, item.duration_remaining)
                    "
                  >
                    <v-btn :disabled="table.loading" color="primary">
                      {{ item.duration_remaining }}
                    </v-btn>
                    <template #input>
                      <v-text-field
                        v-model="item.duration_remaining"
                        :rules="[v => !!v || $t('app.required')]"
                        type="number"
                        single-line
                      />
                    </template>
                  </v-edit-dialog>
                </td>
              </tr>
              <tr v-else :key="index">
                <td>{{ item.name }}</td>
                <td colspan="5">
                  <v-btn
                    :loading="loadingGenerate"
                    color="success"
                    block
                    @click="generateChanceHandler(item)"
                    >{{ $t("app.generate_chance") }}</v-btn
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
      <Pagination
        :length="dataTable.body.last_page"
        :total="dataTable.body.total"
        :current-page="table.page"
        :limit="table.limit"
        :handler="paginateChange"
      />
    </div>
  </v-card>
</template>

<script>
import store from "@/store";
import i18n from "@/i18n";
import {
  listChance,
  updateChance,
  updateDuration,
  generateChance
} from "@/api/admin/academic/eTest";

export default {
  components: {
    HeaderInfo: () => import("../../components/HeaderInfo"),
    Pagination: () => import("@/components/Pagination")
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  mounted() {
    this.getListChance();
  },
  watch: {
    "table.search"() {
      this.getListChance();
    }
  },
  data() {
    return {
      tableHeaders: [
        {
          text: i18n.t("e_test.table_header.chance.name"),
          value: "name"
        },
        {
          text: i18n.t("e_test.table_header.chance.chance"),
          value: "chance"
        },
        {
          text: i18n.t("e_test.table_header.chance.opportunity_missed"),
          value: "chance_used"
        },
        {
          text: i18n.t("e_test.table_header.chance.remaining_opportunity"),
          value: "chance_remaining"
        }
      ],
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false,
        etest_class: this.$route.params.etestClass
      },
      loadingGenerate: false,
      dataTable: {
        header: {},
        body: {
          data: []
        }
      }
    };
  },
  methods: {
    generateChanceHandler(std) {
      this.loadingGenerate = true;
      generateChance({
        student: std.student,
        etest_class: this.$route.params.etestClass
      })
        .then(res => {
          if (res.data.code) {
            this.getListChance();
          } else {
            this.snackBar(false, res.data.message);
          }
          this.loadingGenerate = false;
        })
        .catch(() => {
          this.loadingGenerate = false;
          this.snackBar(false, i18n.t("app.there_is_an_error"));
        });
    },
    async saveChance(item) {
      const body = {
        student: item.student,
        etest_class: this.$route.params.etestClass,
        new_chance: item.chance
      };
      if (item.chance >= item.chance_remaining) {
        try {
          const response = await updateChance(body);
          if (response.data.code) {
            this.snackBar(true, i18n.t("e_test.msg.success_update_chance"));
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          console.error("saveChance(item)\n", error);
          this.snackBar(false, i18n.t("app.there_is_an_error"));
        }
      } else {
        this.snackBar(false, i18n.t("e_test.msg.validate_update_chance"));
      }
      this.getListChance();
    },
    async updateDuration(student, duration) {
      const body = {
        student: student,
        etest_class: this.$route.params.etestClass,
        new_duration_remaining: duration
      };
      try {
        const response = await updateDuration(body);
        if (response.data.code) {
          this.snackBar(true, i18n.t("e_test.msg.success_update_duration"));
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("updateDuration(student, duration)\n", error);
        this.snackBar(false, i18n.t("app.there_is_an_error"));
      }
      this.getListChance();
    },
    paginateChange(page, limit) {
      this.table.limit = limit;
      this.table.page = page;
      this.getListChance();
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async getListChance() {
      this.table.loading = true;
      try {
        const response = await listChance(this.table);
        if (response.data.code) {
          this.dataTable = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("getListChance()\n", error);
        this.snackBar(false, i18n.t("app.there_is_an_error"));
        this.table.loading = false;
      }
      this.table.loading = false;
    }
  }
};
</script>
